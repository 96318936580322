import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { AdminInteractionReason, AdminInteractionsStatsForTransactionsWithShippingGetResponse, ShippingProviderType } from "@reshopper/admin-client";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { useEffect, useState } from "react";

export default function TransactionsInteractionsTable() {
    const startOfToday = startOfDay(new Date());
    const endOfToday = endOfDay(new Date());
    const [fromUtc, setFromUtc] = useState<Date>(subDays(startOfToday, 29));
    const [toUtc, setToUtc] = useState<Date>(endOfToday);

    const [shippingProviderTypes, setShippingProviderTypes] = useState<undefined | ShippingProviderType[]>();
    const [adminInteractionCounts, setAdminInteractionCounts] = useState<AdminInteractionsStatsForTransactionsWithShippingGetResponse>();

    useEffect(() => {
        const getShippingProviders = async () => {
            const result = await adminClient().adminGetEnumsFromShippingProviderType({
                excludedShippingProviderTypes: ["coolRunnerDaoPickup", "coolRunnerDaoHome"]
            });
            setShippingProviderTypes(result.shippingProviderTypes);
        }

        getTableData();
        getShippingProviders();
    }, [])

    const getTableData = async () => {
        const response = await adminClient().adminInteractionsStatsForTransactionsWithShippingGet({
            fromUtc,
            toUtc
        });

        setAdminInteractionCounts(response);
    }

    function onSubmit(fromDate: Date | null, toDate: Date | null) {
        if (!fromDate || !toDate) {
            return;
        }
        setFromUtc(fromDate);
        setToUtc(toDate);
        getTableData();
    }

    const getTotalCountForShippingProviderType = (shippingProviderType: ShippingProviderType): number | undefined => {
        if (!adminInteractionCounts || !shippingProviderTypes) {
            return;
        }
        
        return adminInteractionCounts.totalInteractionsByShippingProviderType.find(adminInteractionCount => adminInteractionCount.shippingProviderType === shippingProviderType)?.totalInteractionsByShippingProviderType ?? 0;
    }

    const getCountForReasonAndShippingProviderType = (reason: AdminInteractionReason, shippingProviderType: ShippingProviderType): number | undefined => {
        if (!adminInteractionCounts || !shippingProviderTypes) {
            return;
        }

        const interactionCountsByReasonAndShippingProviderType = adminInteractionCounts.entries
            .find(adminInteractionCount => adminInteractionCount.reason === reason)?.interactionCountsByReasonAndShippingProviderType
            .find(interactionCountByReasonAndShippingProviderType => interactionCountByReasonAndShippingProviderType.shippingProviderType == shippingProviderType)
            ?.totalInteractionsByReasonAndShippingProviderType ?? 0;

        return interactionCountsByReasonAndShippingProviderType;
    }

    const getShippingProviderPercentageOutOfTotalInteractions = (shippingProviderType: ShippingProviderType) => {
        if (!adminInteractionCounts) {
            return 0
        }
        var totalTransactionsWithAdminInteractionsCount = adminInteractionCounts.totalTransactionsWithAdminInteractionsCount;
        var totalTransactionsWithAdminInteractionsCountByProvider = adminInteractionCounts.totalTransactionsByShippingProvider.find(x => x.shippingProviderType === shippingProviderType)?.totalCountByShippingProvider ?? 0;
        
        return " (" + (totalTransactionsWithAdminInteractionsCountByProvider/totalTransactionsWithAdminInteractionsCount*100).toFixed(1) + "%)";
    }

    const getInteractionsPercentage = (count: number) => {
        if (!adminInteractionCounts) {
            return 0
        }
        var totalReasonInteractions = adminInteractionCounts?.totalInteractionsByShippingProviderType.reduce((partialSum, x) => partialSum + x.totalInteractionsByShippingProviderType, 0);
        
        return (count/totalReasonInteractions*100).toFixed(1);
    }

    const getShippingProviderPercentage = (shippingProviderType: ShippingProviderType | undefined) => {
        if (!adminInteractionCounts) {
            return
        }
        var totalInteractions = adminInteractionCounts?.totalInteractionsByShippingProviderType.reduce((partialSum, x) => partialSum + x.totalInteractionsByShippingProviderType, 0);
        var total = adminInteractionCounts?.totalInteractionsByShippingProviderType.find(adminInteractionCount => adminInteractionCount.shippingProviderType === shippingProviderType)?.totalInteractionsByShippingProviderType ?? 0;
        
        return (total/totalInteractions*100).toFixed(1);
    }

    if (!adminInteractionCounts || !shippingProviderTypes) {
        return <></>
    }

    return <>
        <Typography variant="h5" style={{ marginTop: "10px", marginBottom: "10px" }}>Support interactions</Typography>
        <DateRangePickerComponent
            onSubmit={onSubmit}
            renderTimeOption={false}
            renderDateRanges={true}
            disableFutureDates={true}
            disablePastDates={false}
            submitOnLoad={false}
            variant="outlined"
            defaultFromDate={fromUtc}
            defaultToDate={toUtc}
        />
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell />
                    {shippingProviderTypes.map((provider, index) =>
                        <TableCell align="left" key={index + provider}>{provider}{getShippingProviderPercentageOutOfTotalInteractions(provider)}</TableCell>
                    )}
                    <TableCell align="left">totalInteractions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {adminInteractionCounts.entries.map((adminInteractionCount, index) => (
                    <TableRow key={index + adminInteractionCount.reason + "row"}>
                        <TableCell align="left" key={index + adminInteractionCount.reason}>{adminInteractionCount.reason}</TableCell>
                        {adminInteractionCount.interactionCountsByReasonAndShippingProviderType.map((interactionCountByReasonAndShippingProvider, innerIndex) => (
                            <TableCell align="left" key={index + adminInteractionCount.reason + interactionCountByReasonAndShippingProvider.shippingProviderType}>
                                {getCountForReasonAndShippingProviderType(adminInteractionCount.reason, shippingProviderTypes[innerIndex])}
                            </TableCell>
                        ))}
                        <TableCell align="left" key={index + adminInteractionCount.reason + "total"}>{adminInteractionCount.totalInteractionsByReason}{" ("}{getInteractionsPercentage(adminInteractionCount.totalInteractionsByReason)}{"%)"}</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell align="left">totalInteractions</TableCell>
                    {adminInteractionCounts.totalInteractionsByShippingProviderType.map((totalInteractionsByShippingProvider, index) => (
                        <TableCell align="left" key={index + totalInteractionsByShippingProvider.shippingProviderType + "total"}>{getTotalCountForShippingProviderType(shippingProviderTypes[index])}{" ("}{getShippingProviderPercentage(shippingProviderTypes[index])}{"%)"}</TableCell>
                    ))}
                    <TableCell align="left">{adminInteractionCounts.totalInteractionsByShippingProviderType.reduce((partialSum, x) => partialSum + x.totalInteractionsByShippingProviderType, 0) ?? 0}{" (100%)"}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </>
}